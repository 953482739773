.btn{
	border-radius: 23px;
	font-family: $font-button;
	font-weight: 700;
	padding: 0.5rem 1.5rem;
}
.btn-primary {
	color: #000000;
	background-color: #ffc400;
	border-color: #ffc400;

	&:hover{
		color: #fff;
		background-color: #3c3c3c;
		border-color: #3c3c3c;
	}
}
.btn-secondary{
	color: #fff;
	background-color: #3c3c3c;
	border-color: #3c3c3c;
	&:hover{
		background-color: #fff;
		color: #3c3c3c;
	}
}
h2,h3{
	font-family: $anton;
	color: #3c3c3c;
}
h3{
	font-size: sizeToRem(26);
}
p{
	color: #3c3c3c;;
}

.container{
	>header{
		text-align: center;
		h2{
			font-size: sizeToRem(31);
		}
		.small-p{
			font-size: sizeToRem(10);
		}
		p{
			font-size: sizeToRem(14);
		}
	}
}
.link-naranja{
	color: #ffb100;
	&:hover{
		color: #000;
		text-decoration: none;
	}
}

.form-group{
	position: relative;
}
.form-group span.icon{
	display: block;
	position: absolute;
	top: 40px;
	right: 4px;
	font-size: 1.5rem;
	line-height: 1;
}
.form-group span.icon.dripicons-cross{
	color: #8e0d0d
}
.form-group span.icon.dripicons-checkmark{
	color: #17a817;
}
em.error {
	font-size: sizeToRem(12);
	color: #8e0d0d;
	margin-top: 0;
	display: block;
}
.form-check{
	.icon{
		display: none;
	}
}

.title-site{
	padding: 30px 0 20px;
	header{
		text-align: center;
		.img-logo{
			max-width: 90px;
		}
		h1{
			font-family: 'Impact',$montserrat;
			color: #000;

			a{
				color: inherit;
				font-family: inherit;
				font-size: inherit;
				&:hover{
					text-decoration: none;
				}
			}
		}
		.link-retornar{
			color: #000;
			text-decoration: none;
			background-color: transparent;
			border: 1px solid #000;
			border-radius: 20px;
			padding: 1px 8px;
			margin-top: 10px;
			display: inline-block;

			&:hover{
				text-decoration: none;
			}
		}
	}
}
