@import url('https://fonts.googleapis.com/css?family=Anton|Montserrat:400,700|Open+Sans:400,600,700&display=swap');

$montserrat: 'Montserrat', sans-serif;
$openSans: 'Open Sans', sans-serif;
$anton: 'Anton', sans-serif;

$font-family-base: $openSans !default;
$font-button: $montserrat;

$title: $montserrat;
