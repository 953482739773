/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 991px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}
.flexslider{
  border: none;
  margin-bottom: 15px;
}

.flexslider li .meta {
  position: absolute;
  bottom: 0;
  left: 65%;
  top: 40%;
  transform: translate(-65%,-40%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  max-width: 480px;
  height: 192px;
  background-color: transparent;
  padding: 10px 15px 0 20px;
  text-align: right;

}

.flexslider li h2 {
  margin: 0;
  font-weight: 700;
  color: #222;
  font-size: sizeToRem(40);
  text-transform: uppercase;
  line-height: 1;
  font-family: $montserrat;

  strong{
    color: #df2713;
    display: block;
    font-size: sizeToRem(76);
  }
}


.flexslider li .meta,
.flexslider li h1,
.flexslider li h2,
.flexslider li .category p,
.flexslider li .category span {
  -webkit-animation-duration: .6s;
  animation-duration: .6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
.flexslider li .meta{
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
.flexslider li.flex-active-slide .meta,
.flexslider li.flex-active-slide .meta h1,
.flexslider li.flex-active-slide .meta h2,
.flexslider li.flex-active-slide .meta .category p,
.flexslider li.flex-active-slide .meta .category span {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
  -webkit-animation-duration: .6s;
  animation-duration: .6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.flexslider li.flex-active-slide .meta{
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.flexslider li.flex-active-slide .meta h2 {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

.flexslider li.flex-active-slide .meta .category p {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.flexslider li.flex-active-slide .meta .category span {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}

.flex-direction-nav a:before{
  color: rgba(255, 255, 255, 0.65);
  font-size: 32px;
}
.flex-control-nav {
  bottom: 70px;
}

.flex-direction-nav .flex-prev {
  left: 0;
}
.flex-direction-nav .flex-next {
  right: 0;
}

/*.flex-direction-nav {
  position: absolute;
  top: 0;
  left: 30px;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 30px;
  height: 30px;
  background-color: #333;
  margin: -20px 0 0;
  position: absolute;
  top: 50px;
  z-index: 10;
  overflow: hidden;
  opacity: .5;
  cursor: pointer;
  color: #fff;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
}

.flex-direction-nav .flex-prev {
  text-align: left;
  left: -10px;
}

.flex-direction-nav .flex-next {
  text-align: right;
  left: 30px;
}

.flexslider:hover .flex-prev {
  left: -10px;
}

.flexslider:hover .flex-next {
  left: 30px;
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
  opacity: 1;
}

.flex-direction-nav a:before {
  font-family: FontAwesome;
  content: '\f104';
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  padding: 2px;
  font-size: 19px;
  line-height: 27px;
}

.flex-direction-nav a.flex-next:before {
  content: '\f105';
}*/

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeIn{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;

  }
}

@keyframes fadeOut{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;

  }
}


@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}