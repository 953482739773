.mob-nav-footer {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 10000;
  background: #00beb4;
  padding: 0;
  box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.25);

  .mob-footer-nav {
    list-style: none;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    height: 65px;
    bottom: 0;
    position: relative;

    .footer-nav-item {
      width: 20%;
      float: left;
      text-align: center;
      margin: 0 !important;

      .link-footer-nav-item {
        display: block;
        color: #fff;
        position: relative;
        font-size: 0.6875rem;
        z-index: 10;

        .image-footer-nav {
          display: inline-block;
          margin-top: 5px;
          width: 40px;
          height: 38px;
          background-repeat: no-repeat;
          background-color: transparent;
          background-size: 100%;
          background-position: 0 0;

          &.catalogos-footer{
            background-image: url("../images/footer-nav/Catalogo.png");
            background-size: 100%;
            background-position: 0 0;
          }
          &.tips-footer{
            background-image: url("../images/footer-nav/Tips.png");
            background-size: 100%;
            background-position: 0 0;
          }
          &.productos-footer{
            background-image: url("../images/footer-nav/Productos.png");
            background-size: 100%;
            background-position: 0 0;
          }
          &.ofertas-footer{
            background-image: url("../images/footer-nav/Ofertas.png");
            background-size: 100%;
            background-position: 0 0;
          }
          &.pedidos-footer{
            background-image: url("../images/footer-nav/Ajustes.png");
            background-size: 100%;
            background-position: 0 0;
          }
        }

        .text-footer-nav {
          display: block;
          color: inherit;
        }
      }
    }
  }

}

@media (max-width: 991px){
  .mob-nav-footer {
    display: block;
  }
}
