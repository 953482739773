@import "flex_slider";

#mapaRegistro {
	height: 450px;
	width: 100%;
}
#googleMapsAcopio{
	height: 550px;
	width: 100%;
}

@mixin buttonInner(){
	border-radius: 36px;
	background-image: linear-gradient(180deg, #67d735 0%, #267e41 100%);
	border: none;
	color: #ffffff;
	font-family: "Anton", sans-serif;
	font-size: 1.4rem;
	font-weight: 400;
	text-transform: initial;
}
@mixin buttonInner2(){
	border-radius: 36px;
	background-image: linear-gradient(180deg, #eac908 0%, #dc7500 100%);
	border: none;
	color: #ffffff;
	font-family: "Anton", sans-serif;
	font-size: 1.6rem;
	font-weight: 400;
	text-transform: initial;
}
.btn{
	&.btn-new{
		@include buttonInner();
	}
	&.btn-new2{
		@include buttonInner2();
	}
}
/*Main Banner*/
.main-banner{
	background-color: #f4f4f4;
	//height: 406px;

	.text-main-banner{
		max-width: 350px;
		width: 100%;
		color: #fff;
		text-align: center;
		margin: 0 auto;
		padding: 35px 0 20px;
	}
	h1{
		font-family: 'Impact',$montserrat;
		font-size: sizeToRem(49);
		margin-top: 10px;
	}
	p{
		font-size: sizeToRem(14);
		//color: #fff;
		max-width: 718px;
		display: inline-block;
	}
	.btn-main-banner{
		text-align: center;
		padding-top: 20px;
		.btn-half{
			//text-transform: uppercase;
			margin-right: 2%;
			//@include buttonInner();
			border-radius: 10px;
			font-size: sizeToRem(16);
			&.btn2{
				//@include buttonInner2();
			}
			&:last-child{
				margin-right: 0;
			}
		}
	}
}
hr{
	border-top: 1px solid #b4b4b4;
	width: 100%;
}
.box-compartir-listado{
	.btn{
		@include buttonInner2();
	}
}
img{
	max-width: 100%;
}
.lista-donaciones{
	padding: 50px 0 40px;

	.box-necesario{
		margin-right: 0px;
		//max-width: 192px;
		padding-bottom: 5px;
		margin-bottom: 15px;

		&:hover{
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		}
		.img-necesario {
			border: 1px solid #dedede;
			max-width: 190px;
			width: 100%;
			height: 150px;
			overflow: hidden;
			position: relative;
			margin: 0 auto 7px;

			img{
				max-width: 100%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
			}
		}
		p{
			max-width: 210px;
			margin: 0 auto;
			text-align: center;
			line-height: 1.2;
			color: #000;
		}

	}

	.text-necesario{
		p{
			margin-bottom: 5px;
		}
		.lista-importante {
			padding: 0;
			font-size: 14px;
			font-weight: 700;
			list-style: none;
		}
	}
}

.lista-donaciones-mapa{
	padding: 40px 0 60px;
	.opciones-donaciones-mapa{
		margin-top: 20px;
		padding-bottom: 10px;
	}
	.box-select-ciudad{
		label{
			font-family: $montserrat;
			font-size: sizeToRem(18);
			margin-right: 10px;
		}
		select{
			padding: 0.375rem 1rem;
			border-radius: 100px;
		}
	}
	.box-btn-registrar{
		text-align: right;
		.btn{
			text-align: center;
			@include buttonInner();
		}
	}
	.box-titulo-listado{
		margin: 20px 0 25px;
	}
}

.box-list-acopio{
	.col-mgb{
		margin-bottom: 25px;
	}
	.li-map-link {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
		border: 3px solid transparent;
		&:hover{
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
		}

		.header-card{
			h3{
				line-height: 1;
				margin-bottom: 10px;
				padding: 5px 15px 10px;
				background-color: #267e41;;
				a{
					//color: #527700;
					color: #fff;
					font-family: $openSans;
					font-size: sizeToRem(20);
					font-weight: 600;
					letter-spacing: -0.3px;
					line-height: 1;
					text-decoration: none;
					&:hover{
						text-decoration: none;
					}
				}
			}
		}
		.body-card{
			padding: 0 15px;
			p{
				font-size: sizeToRem(14);
				margin-bottom: 5px;
			}
			.direccion{
				font-size: 1rem;
				margin-bottom: 8px;
				font-weight: 600;
				a{
					color: #3c3c3c;
					&:hover{
						text-decoration: none;
					}
				}
			}
			.telefono{
				margin-bottom: 8px;
			}
			.validacion{
				color: #70a200;
				font-size: sizeToRem(12);
				font-weight: 700;
			}
			.body-card-info{
				.lista-material-centro{
					padding: 0;
					list-style: none;
					li{
						font-size: sizeToRem(12);
						line-height: 1.3;
						padding: 3px 10px 5px;

						&:nth-child(2n){
							background-color: #f7f7f7;
						}
						&:hover{
							color: #000;
						}
					}
				}
			}
		}
		.footer-card{
			padding: 5px 15px 15px;

			.ul-icon{
				list-style: none;
				display: inline-block;
				padding: 0;
				margin: 0;
				vertical-align: middle;
				width: 100%;
				li{
					display: inline-block;
					margin-right: 10px;
					a{
						display: inline-block;
						.icon-pin{
							vertical-align: middle;
							display: inline-block;
						}
						.icon-card{
							display: inline-block;
							&.text {
								color: #d30000;
							}
						}
						.fa{
							font-size: 1.5rem;
							vertical-align: middle;
							padding-top: 3px;
							&.text {
								color: #d30000;
							}
						}
						span.text{
							display: inline-block;
							font-size: 0.7rem;
							vertical-align: middle;
							padding-left: 2px;
							color: #d30000;
							text-shadow: 0 0 1px rgba(0,0,0,0.25);
						}
						&.btn{
							padding: 0.5rem 1rem;
							line-height: 1;
							span{
								font-size: 0.8rem;
								vertical-align: middle;
							}
							.fa{
								width: auto;
								height: auto;
								margin-left: 3px;
								font-size: 1rem;
							}
						}
					}
				}
			}
			.validar-centro{
				display: inline-block;
				vertical-align: middle;
				width: 65%;
				text-align: right;
				.link-validar{
					a{
						color: #939393;
						span{
							font-weight: 700;
							font-size: sizeToRem(14);
						}
						&:hover{
							text-decoration: none;
							color: #70a200;
						}
					}
				}
			}
			.icon-card{
				width: 32px;
				height: 32px;
				display: block;

				&.icon-phone{
					background: url("../images/phone.png") no-repeat center;
				}
				&.icon-pin{
					background: url("../images/pin.png") no-repeat center;
				}
				&.icon-valido{
					background: url("../images/valido.png") no-repeat center;
					display: inline-block;
					vertical-align: middle;
				}
				&.icon-validado{
					background: url("../images/validado.png") no-repeat center;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
	.box-active{
		.li-map-link{
			border: 3px solid #F5C400;
			box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5)
		}
	}
}

.kit-activismo{
	padding: 60px 0 50px;
	background-color: #ffc400;

	header{
		h2{
			text-transform: uppercase;
		}
	}
	.text-kit-activismo{
		margin-bottom: 15px;
		max-width: 700px;
		width: 100%;
		margin: 20px auto 30px;
	}
	.btn{
		width: 300px;
		text-transform: uppercase;
		padding: 0.5rem 1.5rem;
	}

}
.kit-activismo-page{
	padding: 30px 0 50px;
}

.directorio{
	padding: 80px 0 60px;
	header{
		h2{
			text-transform: uppercase;
			margin-bottom: 20px;
		}
		p{
			max-width: 800px;
			width: 90%;
			margin: 0 auto 30px;
		}
	}
	.col-directorio{
		margin-bottom: 20px;
	}
	.box-directorio {
		border: 1px solid #d5d5d5;
		text-align: center;
		padding: 20px 15px 20px;
		&:hover{
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
		}
		.img-box-directorio{
			min-height: 130px;
			position: relative;

			img{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
		h3{
			font-family: $openSans;
			font-weight: 600;
			font-size: 1rem;
			margin: 20px 0 8px;
		}
		.text-directorio{
			margin-bottom: 10px;
			p{
				font-size: 12px;
				margin-bottom: 5px;
				line-height: 1.2;
			}
		}
		.url-directorio,.email-directorio{
			font-size: 12px;
			margin-bottom: 8px;
			display: block;
			&:hover{
				text-decoration: none;
			}
		}
		.btn{
			width: 100%;
		}
	}
}

.apoyan{
	background-color: #f2f2f2;
	padding: 50px 0 40px;
}
.apoyo{

	img{
		margin: 25px 30px;
	}
}
.owl-carousel-marca {
	margin-top: 30px;
	.owl-item img {
		max-width: 145px;
	}
}
.gracias{
	padding: 20px 0 60px;
}
.btn-gracias{
	.btn{
		margin-right: 7px;
		margin-bottom: 15px;
		min-width: 215px;
	}
}
footer{
	padding: 15px 0 15px;
	.footer-p{
		color: #3c3c3c;
		font-size: 11px;
	}
}
.autor-externo{
	font-size: 6px;
}
.formulario-acopio{
	padding: 10px 0 30px;
	
	.col-main-form{
		margin-top: 15px;
		padding-top: 20px;
		padding-bottom: 25px;
		background-color: #f2f2f2;
	}

	.materiales-acopio-form{
		padding: 20px 10px;
		border: 1px solid #ced4da;
		border-radius: 5px;
		margin-bottom: 10px;
		.field_wrapper{
			>.row{
				margin-bottom: 10px;
			}

			.remove_button{
				font-size: 1.5rem;
				color: #8e0d0d;
			}
		}
	}
}

.btn-form{
	max-width: 370px;
	width: 100%;
	display: block;
	margin: 40px auto 10px;
}

.boxInfoMap{
	padding: 10px 15px;
	h4{
		color: #000;
		font-family: $openSans;
		text-align: center;
		padding-bottom: 2px;
		border-bottom: 1px solid #f3f3f3;
		font-weight: 600;
	}
	p{
		font-family: $openSans;
		margin-bottom: 5px;
		font-size: 1rem;
		strong{
			font-weight: 600;
			color: #000;
		}
	}
	.btn-map{
		margin-top: 15px;
		border: 1px solid #3c3c3c;
		font-size: 1rem;
		padding: 5px 15px;

		&:hover{
			background-color: #3c3c3c;
			color: #fff;
		}
	}
}
.contacto{
	padding: 40px 0 50px;
}

.banner-body{
	background: url(../images/incendio-bg.jpg) top center no-repeat #000;
	background-size: cover;
	padding-top: 50px;
	padding-bottom: 50px;
}

.tabs-sector{
	padding-top: 20px;
	padding-bottom: 20px;
	.tabs-list {
		display: block;
		width: 100%;
		text-align: center;
		.tabs-item {
			display: inline-block;
			list-style: none;
			&:first-child{
				margin-right: -6px;
			}
			a{
				border: 1px solid #707070;
				background-color: #ffffff;
				display: block;
				color: #313131;
				font-family: $openSans;
				font-size: sizeToRem(18);
				font-weight: 400;
				padding: 10px 25px;
				min-width: 250px;
				position: relative;
				text-align: center;
				.fa{
					position: absolute;
					top: 15px;
					left: 25px;
				}
				&:hover{
					border: 1px solid #707070;
					background-color: #313131;
					color: $white;
					text-decoration: none;
				}
			}
			&.current{
				a{
					border: 1px solid #707070;
					background-color: #313131;
					color: $white;
				}
			}
		}
	}
	.tabs-container {
		width: 100%;
		position: relative;
		.tabs-content {
			position: absolute;
			visibility: hidden;
			&.current{
				position: relative;
				visibility: visible;
			}
		}
	}
}

.search-form{
	.btn-new,
	.btn-new2{
		font-size: sizeToRem(17);
		border-radius: 10px;
	}
}
