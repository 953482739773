/*responsive*/
@media (max-width: 480px){

	.main-form{
		.materiales_acopio{
			font-size: 0.9rem;
		}
		.add_button{
			font-size: 0.8rem;
		}
	}

}
@media (max-width: 575px){

	.main-banner{
		//height: 410px;
		h1{
			font-size: sizeToRem(40);
		}

		.btn-main-banner .btn-half{
			width: 90%;
			max-width: 350px;
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
	.boxInfoMap{

		h4 {
			font-size: sizeToRem(19);
		}
		p{
			font-size: sizeToRem(14);
		}
		.btn-map{
			font-size: sizeToRem(12);
		}
	}



}

@media (min-width: 576px) and (max-width: 767px)
{

}

@media (max-width: 767px) {
	.box-select-ciudad{
		margin-bottom: 15px;
	}
	.lista-donaciones-mapa .box-btn-registrar{
		text-align: center;
		.btn{
			font-size: 0.9rem;
		}
	}
	.main-banner {
		.text-main-banner {
			img{
				&.img-fluid{
					max-height: 120px;
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px)
{
	.lista-donaciones-mapa .box-btn-registrar{

		.btn{
			font-size: 0.8rem;
		}
	}

}

@media (max-width: 991px)
{



}

@media (min-width: 992px)
{

}

@media (min-width: 992px) and (max-width: 1199px)
{


}
